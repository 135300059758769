//
// Symbol
//

@mixin symbol-size($size) {
    >img {
        width: $size;
        height: $size;
    }

    .symbol-label {
        width: $size;
        height: $size;
    }

    &.symbol-fixed {
        .symbol-label {
            width: $size;
            height: $size;
        }

        >img {
            width: $size;
            height: $size;
            max-width: none;
        }
    }

    // Ratios
    &.symbol-2by3 {
        .symbol-label {
            height: $size;
            width: $size * divide(3, 2);
        }

        >img {
            height: $size;
            width: $size * divide(3, 2);
            max-width: none;
        }
    }

    &.symbol-1by1-75 {
        .symbol-label {
            height: $size;
            width: $size * divide(1.734, 1);
        }

        >img {
            height: $size;
            width: $size * divide(1.734, 1);
            max-width: none;
        }
    }

    &.symbol-1by2-75 {
        .symbol-label {
            height: $size;
            width: $size * divide(2.75, 1);
        }

        >img {
            height: $size;
            width: $size * divide(2.75, 1);
            max-width: none;
        }
    }

    &.symbol-1by7-76 {
        .symbol-label {
            height: $size / 2.8;
            width: $size * divide(7.76, 1) / 2.8;
        }

        >img {
            height: $size / 2.8;
            width: $size * divide(7.76, 1) / 2.8;
            max-width: none;
        }
    }
}